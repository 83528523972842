export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'adn',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.346.19',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'inspections'
	],
	TIMESTAMP: '2024-11-16T09:59:23.708Z',
	COMMIT: '0623a18b5ead368fc19d3370413c8fc86682c48e',
	BRANCH: 'deploy',
	API_SERVER: 'https://adn-inspections-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://adn-inspections-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: 'https://adn-inspections-digital-twin.unoplatform.io'
};
