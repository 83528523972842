import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {InspectionProjectService} from 'src/app/modules/inspections/services/inspection-project.service';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {GAGapOrigin, GAGapOriginLabel} from 'src/app/models/gap-analysis/gaps/gap-origin';
import {AssetTypeListNameResponse, AssetTypeListParams, AssetTypeService} from 'src/app/modules/asset-portfolio/services/asset-type.service';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest, UnoOptionsLazyComponent} from '../../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {UUID} from '../../../../../models/uuid';
import {GAGapStatus, GAGapStatusLabel} from '../../../../../models/gap-analysis/gaps/gap-status';
import {GAGapPriorityLabel} from '../../../../../models/gap-analysis/gaps/gap-priority';
import {ObjectKeysPipe} from '../../../../../pipes/object-keys.pipe';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {GAGapCountParams, GAGapListParams, GapAnalysisService} from '../../../services/gap-analysis.service';
import {GAGapExport} from '../../data/gap-export';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * List of possible recommendation filters for the gap entries.
 */
const GAGapHasRecommendationsFilter = {
	ALL: -1,
	NO: 0,
	YES: 1
};

/**
 * List of possible recommendation filters labels for the gap entries.
 */
const GAGapHasRecommendationsFilterLabel: Map<number, string> = new Map([
	[GAGapHasRecommendationsFilter.ALL, 'all'],
	[GAGapHasRecommendationsFilter.NO, 'no'],
	[GAGapHasRecommendationsFilter.YES, 'yes']
]);

@Component({
	selector: 'gap-list-page',
	templateUrl: 'gap-list.page.html',
	styleUrls: ['./gap-list.page.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoOptionsLazyComponent,
		UnoButtonComponent,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		ObjectKeysPipe,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class GapListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app = App;

	public selfStatic = GapListPage;

	public session = Session;

	public userPermissions = UserPermissions;

	/**
	 * Indicates if all items are selected
	 */
	public selectAll: boolean = false;

	/**
	 * List of selected gap items UUIDs from the list.
	 */
	public selectedGapUuids: UUID[] = [];

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.headerActive('image'), size: 'small'},
		{header: 'projectName', type: UnoTableColumnType.TEXT, attribute: 'projectName', visible: this.headerActive('projectName'), size: 'small', tag: ListDisplayStyle.TITLE, sortBy: 'filtered_gaps.inspection_project_name'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: this.headerActive('assetName'), size: 'small', tag: ListDisplayStyle.TITLE, sortBy: 'filtered_gaps.ap_asset_name'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: this.headerActive('assetTag'), size: 'small', tag: ListDisplayStyle.TEXT, sortBy: 'filtered_gaps.ap_asset_tag'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: this.headerActive('status'), tag: ListDisplayStyle.TEXT, size: 'small'},
		{header: 'origin', type: UnoTableColumnType.TEXT, attribute: 'origin', visible: this.headerActive('origin'), tag: ListDisplayStyle.TEXT, size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.headerActive('description'), tag: ListDisplayStyle.TEXT, size: 'medium', sortBy: 'filtered_gaps.ga_gap_description'},
		{header: 'priority', type: UnoTableColumnType.TEXT, attribute: 'priority', visible: this.headerActive('priority'), tag: ListDisplayStyle.TEXT, size: 'medium', sortBy: 'filtered_gaps.ga_gap_priority'},
		{header: 'financialImpact', type: UnoTableColumnType.NUMBER, attribute: 'financialImpact', visible: this.headerActive('financialImpact'), tag: ListDisplayStyle.TEXT, size: 'large', sortBy: 'filtered_gaps.ga_gap_financial_impact'},
		{header: 'fieldLabel', type: UnoTableColumnType.TEXT, attribute: 'fieldLabel', visible: this.headerActive('fieldLabel'), tag: ListDisplayStyle.TEXT, size: 'medium', sortBy: 'filtered_gaps.inspection_field_label'},
		{header: 'fieldText', type: UnoTableColumnType.TEXT, attribute: 'fieldText', visible: this.headerActive('fieldText'), tag: ListDisplayStyle.TEXT, size: 'small', sortBy: 'filtered_gaps.inspection_field_text'},
		{header: 'fieldUuid', type: UnoTableColumnType.TEXT, attribute: 'fieldUuid', visible: this.headerActive('fieldUuid'), tag: ListDisplayStyle.LABEL, size: 'small', sortBy: 'filtered_gaps.inspection_field_id'},
		{header: 'recommendations', type: UnoTableColumnType.NUMBER, attribute: 'recommendationsCount', visible: this.headerActive('recommendationsCount'), tag: ListDisplayStyle.TEXT, size: 'small'},
		{header: 'actionPlans', type: UnoTableColumnType.NUMBER, attribute: 'actionPlansCount', visible: this.headerActive('actionPlansCount'), tag: ListDisplayStyle.TEXT, size: 'small'}
	];

	/**
	 * Possible database filter to be used for ordering the inspection workflow list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'filtered_gaps.ga_gap_priority',
			options: [
				{label: 'updatedAt', value: 'filtered_gaps.ga_gap_updated_at'},
				{label: 'createdAt', value: 'filtered_gaps.ga_gap_created_at'},
				{label: 'financialImpact', value: 'filtered_gaps.ga_gap_financial_impact'},
				{label: 'assetName', value: 'filtered_gaps.ap_asset_name'},
				{label: 'assetTag', value: 'filtered_gaps.ap_asset_tag'},
				{label: 'projectName', value: 'filtered_gaps.inspection_project_name'},
				{label: 'priority', value: 'filtered_gaps.ga_gap_priority'},
				{label: 'description', value: 'filtered_gaps.ga_gap_description'},
				{label: 'fieldLabel', value: 'filtered_gaps.inspection_field_label'},
				{label: 'fieldText', value: 'filtered_gaps.inspection_field_text'},
				{label: 'fieldUuid', value: 'filtered_gaps.inspection_field_id'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['filtered_gaps.ap_asset_id', 'filtered_gaps.ap_asset_name', 'filtered_gaps.ap_asset_tag', 'filtered_gaps.ga_gap_id', 'filtered_gaps.ga_gap_description', 'filtered_gaps.inspection_project_name', 'filtered_gaps.inspection_field_label'],
			multiple: true,
			options: [
				{label: 'assetUuid', value: 'filtered_gaps.ap_asset_id'},
				{label: 'assetName', value: 'filtered_gaps.ap_asset_name'},
				{label: 'assetTag', value: 'filtered_gaps.ap_asset_tag'},
				{label: 'financialImpact', value: 'filtered_gaps.ga_gap_financial_impact'},
				{label: 'uuid', value: 'filtered_gaps.ga_gap_id'},
				{label: 'gapDescription', value: 'filtered_gaps.ga_gap_description'},
				{label: 'fieldUuid', value: 'filtered_gaps.inspection_field_id'},
				{label: 'projectUuid', value: 'filtered_gaps.inspection_project_id'},
				{label: 'projectName', value: 'filtered_gaps.inspection_project_name'},
				{label: 'fieldLabel', value: 'filtered_gaps.inspection_field_label'},
				{label: 'fieldText', value: 'filtered_gaps.inspection_field_text'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'status',
			label: 'status',
			default: GAGapStatus.ALL,
			options: Array.from(GAGapStatusLabel.keys()).map((value) => {return {label: GAGapStatusLabel.get(value), value: value};})
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'origin',
			label: 'origin',
			default: GAGapOrigin.ALL,
			options: Array.from(GAGapOriginLabel.keys()).map((value) => {return {label: GAGapOriginLabel.get(value), value: value};})
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'hasRecommendations',
			label: 'hasRecommendation',
			default: GAGapHasRecommendationsFilter.ALL,
			options: [
				{label: GAGapHasRecommendationsFilterLabel.get(GAGapHasRecommendationsFilter.ALL), value: GAGapHasRecommendationsFilter.ALL},
				{label: GAGapHasRecommendationsFilterLabel.get(GAGapHasRecommendationsFilter.NO), value: GAGapHasRecommendationsFilter.NO},
				{label: GAGapHasRecommendationsFilterLabel.get(GAGapHasRecommendationsFilter.YES), value: GAGapHasRecommendationsFilter.YES} 
			]	
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'tableFields',
			label: 'tableFields',
			multiple: true,
			default: ['image', 'projectName', 'assetName', 'assetTag', 'status', 'origin', 'description', 'fieldLabel', 'fieldText', 'recommendationsCount', 'actionPlansCount', 'priority'],
			options: [
				{label: 'image', value: 'image'},
				{label: 'project', value: 'projectName'},
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'status', value: 'status'},
				{label: 'origin', value: 'origin'},
				{label: 'description', value: 'description'},
				{label: 'priority', value: 'priority'},
				{label: 'financialImpact', value: 'financialImpact'},
				{label: 'fieldLabel', value: 'fieldLabel'},
				{label: 'fieldText', value: 'fieldText'},
				{label: 'fieldUuid', value: 'fieldUuid'},
				{label: 'recommendations', value: 'recommendationsCount'},
				{label: 'actionPlans', value: 'actionPlansCount'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'assetTypeUuid',
			label: 'assetType',
			identifierAttribute: 'uuid',
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const data: AssetTypeListParams = {
					from: request.from,
					count: request.count,
					search: request.search,
					searchFields: ['[ap_asset_type].[name]'],
					sortField: '[ap_asset_type].[name]',
					sortDirection: SortDirection.ASC
				};

				const req: AssetTypeListNameResponse = await AssetTypeService.listName(data);
				return {options: req.types, hasMore: req.hasMore, id: req.id};
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options.length > 0) {
					const req = await Service.fetch(ServiceList.assetPortfolio.assetType.get, null, null, {uuid: request.options[0]}, Session.session, true);
					return {options: req.response.type};
				}

				return {options: []};
			},
			getOptionText: (option: any): string => {
				return option.name;
			}
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'assetSubTypeUuid',
			label: 'assetSubType',
			identifierAttribute: 'uuid',
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const data = {
					from: request.from,
					count: request.count,
					search: request.search,
					sortField: '[ap_asset_sub_type].[name]',
					sortDirection: SortDirection.ASC,
					hasGaps: true
				};

				const req = await Service.fetch(ServiceList.assetPortfolio.assetSubType.listName, null, null, data, Session.session);
				return {options: req.response.subTypes, hasMore: req.response.hasMore, id: req.id};
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options.length > 0) {
					const req = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: request.options[0]}, Session.session);
					return {options: req.response.subType};
				}
				return {options: []};
			},
			getOptionText: (option: any): string => {		
				return option.name;
			}
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'inspectionProjectUuid',
			label: 'inspectionProject',
			identifierAttribute: 'uuid',
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const data = {
					from: request.from,
					count: request.count,
					search: request.search,
					searchFields: ['[inspection_project].[name]'],
					sortField: '[inspection_project].[name]',
					sortDirection: SortDirection.ASC
				};

			
				const projects = await InspectionProjectService.list(data);
				return {options: projects.projects, hasMore: projects.hasMore, id: projects.id};
				
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options.length > 0) {
					const req = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: request.options[0]}, Session.session);
					return {options: req.response.project};
				} 

				return {options: []};
			},
			getOptionText: (option: any): string => {
				return option.name;
			}
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter items by.
		 */
		search: '',

		/**
		 * Search fields/attributes to be considered on search.
		 */
		searchFields: [],

		/**
		 * Attribute used to sort the items on the list.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the list.
		 */
		sortDirection: SortDirection.ASC,
			
		/**
		 * The gap status to filter the list by.
		*/
		status: GAGapStatus.ALL,

		/**
		 * The gap origin to filter the list by.
		*/
		origin: GAGapOrigin.ALL,

		/**
		 * Filter the gaps by its recommendations count.
		 */
		hasRecommendations: GAGapHasRecommendationsFilter.ALL,
		
		/**
		 * The UUID of the asset type to filter the list by.
		 */
		assetTypeUuid: null,

		/**
		 * The UUID of the asset sub-type to filter the list by.
		 */
		assetSubTypeUuid: null,

		/**
		 * The UUID of the inspection project to filter the list by.
		 */
		inspectionProjectUuid: null,

		/**
		 * Table fields to be shown.
		 */
		tableFields: []
	}, GapListPage.filterOptions);
	
	public ngOnInit(): void {
		super.ngOnInit();
		App.navigator.setTitle('gaps');

		this.selectAll = false;
		this.selectedGapUuids = [];
	}

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: GAGapListParams = {
			from: count,
			count: pageSize,
			search: GapListPage.filters.search,
			searchFields: GapListPage.filters.searchFields,
			sortField: GapListPage.filters.sortField,
			sortDirection: GapListPage.filters.sortDirection,
			status: GapListPage.filters.status,
			origin: GapListPage.filters.origin,
			hasRecommendations: GapListPage.filters.hasRecommendations,
			assetTypeUuid: GapListPage.filters.assetTypeUuid,
			assetSubTypeUuid: GapListPage.filters.assetSubTypeUuid,
			inspectionProjectUuid: GapListPage.filters.inspectionProjectUuid
		};

		const list = await GapAnalysisService.listGaps(params);

		for (const gap of list.gaps) {
			gap.fieldLabel = gap.inspectionField.label;
			gap.fieldText = gap.inspectionField.text;
			gap.fieldUuid = gap.inspectionField.uuid;
			gap.assetName = gap.asset?.name;
			gap.assetTag = gap.asset?.tag;
			gap.projectName = gap.origin === GAGapOrigin.DL50_INSPECTIONS ? 'dl50' : gap.project?.name;
			gap.status = GAGapStatusLabel.get(gap.status);
			gap.origin = GAGapOriginLabel.get(gap.origin);
			gap.priority = GAGapPriorityLabel.get(gap.priority);
			gap.image = gap.asset?.pictures[0];

			delete gap.inspectionField;
		};

		return {
			elements: list.gaps,
			hasMore: list.hasMore
		};
	};

	/**
	 * Updates when a table row is checked
	 * 
	 * @param output - The checked rows
	 */
	public async checkedRow(output: any): Promise<void> {
		if (typeof output.rows === 'boolean') {
			if (!output.rows) {
				this.resetSelection();
			} else {
				const params: GAGapListParams = {
					search: GapListPage.filters.search,
					searchFields: GapListPage.filters.searchFields,
					sortField: GapListPage.filters.sortField,
					sortDirection: GapListPage.filters.sortDirection,
					status: GapListPage.filters.status,
					origin: GapListPage.filters.origin,
					hasRecommendations: GapListPage.filters.hasRecommendations,
					assetTypeUuid: GapListPage.filters.assetTypeUuid,
					assetSubTypeUuid: GapListPage.filters.assetSubTypeUuid,
					inspectionProjectUuid: GapListPage.filters.inspectionProjectUuid
				};

				this.selectedGapUuids = (await GapAnalysisService.listGaps(params)).gaps.map((value) => {return value.uuid;});
			}
		} else {
			this.checkedTableRows = output.rows;
			this.selectedGapUuids = output.items.map((gap) => {return gap?.uuid;});
		}
	}

	/**
	 * Export gaps and gaps recommendations list as XLSX file.
	 */
	public async exportXLSX(): Promise<void> {
		// Fetch the gaps to be exported, using the selected filters
		const listFilters = {
			search: GapListPage.filters.search,
			searchFields: GapListPage.filters.searchFields,
			sortField: GapListPage.filters.sortField,
			sortDirection: GapListPage.filters.sortDirection,
			status: GapListPage.filters.status,
			origin: GapListPage.filters.origin,
			hasRecommendations: GapListPage.filters.hasRecommendations,
			assetTypeUuid: GapListPage.filters.assetTypeUuid,
			assetSubTypeUuid: GapListPage.filters.assetSubTypeUuid,
			inspectionProjectUuid: GapListPage.filters.inspectionProjectUuid
		};
		
		await GAGapExport.exportXLSX(listFilters, this.selectedGapUuids);
	}

	/**
	 * Reset selection of items.
	 */
	private resetSelection(): void {
		this.selectAll = false;
		this.selectedGapUuids = [];
		this.checkedTableRows = [];
	}

	/**
	 * Create action plan from the list of currently selected gaps.
	 */
	public createActionPlan(): void {
		App.navigator.navigate('/menu/gap-analysis/action-plans/edit', {createMode: true, gapUuids: this.selectedGapUuids});
	}

	/**
	 * Navigate to the gap edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid): Promise<void> {
		await App.navigator.navigate('/menu/gap-analysis/gaps/edit', {uuid: uuid});
	}

	/**
	 * Delete selected items from the list.
	 */
	public async deleteSelection(): Promise<void> {
		if (this.selectedGapUuids.length > 0 || this.selectAll) {
			const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
			if (confirm) {
				await GapAnalysisService.deleteGapsBatch(this.selectedGapUuids);
				Modal.toast(Locale.get('deleteSuccessfully'));
				this.resetSelection();

				await this.reset();
			}
		} else {
			Modal.toast(Locale.get('nothingWasDeleted'));
		}
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearchChange(search: string): Promise<void> {
		GapListPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		GapListPage.filters = filters;
		await this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		const params: GAGapCountParams = {
			search: GapListPage.filters.search,
			searchFields: GapListPage.filters.searchFields,
			status: GapListPage.filters.status,
			origin: GapListPage.filters.origin,
			hasRecommendations: GapListPage.filters.hasRecommendations,
			assetTypeUuid: GapListPage.filters.assetTypeUuid,
			assetSubTypeUuid: GapListPage.filters.assetSubTypeUuid,
			inspectionProjectUuid: GapListPage.filters.inspectionProjectUuid
		};

		this.table.sortDirection = GapListPage.filters.sortDirection;
		this.table.sortField = GapListPage.filters.sortField;

		this.tableLayout.forEach((column) => {
			column.visible = this.headerActive(column.attribute);
		});

		this.tableTotalItemsCount = await GapAnalysisService.countGaps(params);

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * When the user changes the sort using the table.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === GapListPage.filters.sortField) {
			GapListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			GapListPage.filters.sortField = sortBy;
			GapListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Check if a table header is active.
	 * 
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active.
	 */
	public headerActive(attribute: string): boolean {
		return GapListPage.filters.tableFields.indexOf(attribute) !== -1;
	}
}
