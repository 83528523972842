import {DashboardBarChartComponent} from 'src/app/components/dashboard/bar-chart/dashboard-bar-chart';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {DashboardLineChartComponent} from 'src/app/components/dashboard/line-chart/dashboard-line-chart.component';
import {DashboardDoughnutChartComponent} from 'src/app/components/dashboard/doughnut-chart/dashboard-doughnut-chart.component';
import {DemoComponentsPage} from 'src/app/modules/demo/demo/components/demo-components.page';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {ChartData} from '../../../screens/dashboards/widgets/widget.component';

export class WidgetDemo {
	/**
	 * The display type of the widget.
	 */
	public displayType = WidgetTypes.DASHBOARD_WIDGET_TYPE_DEMO_WIDGET;

	/**
	 * The visualization elements available to be used for this widget.
	 */
	public visualization = [DashboardBarChartComponent, DashboardDoughnutChartComponent, DashboardLineChartComponent];

	/**
	 * Permissions needed to view the widget.
	 */
	public permissions = [];

	/**
	 * Fetches the chart data to use on the widget
	 * 
	 * @param widget - The widget to fetch data for.
	 * @returns Chart data to use.
	 */
	public static getData(widget: DashboardWidget): ChartData {
		return DemoComponentsPage.loadDemoData(widget);
	};
}
