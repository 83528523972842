import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass} from '@angular/common';
import {UnoAssetSelectorComponent} from '../uno-input/uno-asset-selector/uno-asset-selector.component';
import {UnoAtexInspectionFieldComponent} from '../uno-input/uno-atex-inspection-field/uno-atex-inspection-field.component';
import {UnoAtexTagGroupComponent} from '../uno-input/uno-atex-tag-group/uno-atex-tag-group.component';
import {UnoAudioSelectorComponent} from '../uno-input/uno-audio-selector/uno-audio-selector.component';
import {UnoCompanySelectorComponent} from '../uno-input/uno-company-selector/uno-company-selector.component';
import {UnoDateTimeComponent} from '../uno-input/uno-date-time/uno-date-time.component';
import {UnoFileSelectorComponent} from '../uno-input/uno-file-selector/uno-file-selector.component';
import {UnoGeoPositionComponent} from '../uno-input/uno-geo-position/uno-geo-position.component';
import {UnoImageSelectorComponent} from '../uno-input/uno-image-selector/uno-image-selector.component';
import {UnoKeyValueComponent} from '../uno-input/uno-key-value/uno-key-value.component';
import {UnoNfcTagComponent} from '../uno-input/uno-nfc-tag/uno-nfc-tag.component';
import {UnoOptionsLazyComponent} from '../uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoOptionsMultipleComponent} from '../uno-input/uno-options-multiple/uno-options-multiple.component';
import {UnoOptionsComponent} from '../uno-input/uno-options/uno-options.component';
import {UnoQrCodeComponent} from '../uno-input/uno-qr-code/uno-qr-code.component';
import {UnoTeamSelectorComponent} from '../uno-input/uno-team-selector/uno-team-selector.component';
import {UnoUserSelectorComponent} from '../uno-input/uno-user-selector/uno-user-selector.component';
import {UnoVideoSelectorComponent} from '../uno-input/uno-video-selector/uno-video-selector.component';
import {UnoLateralSelectorSwitchComponent} from '../uno-input/uno-lateral-selector-switch/uno-lateral-selector-switch.component';
import {UnoOptionsSearchComponent} from '../uno/uno-options-search/uno-options-search.component';
import {UnoOptionsList} from '../uno/uno-options-list/uno-options-list.component';
import {UnoPeriodicitySelector} from '../uno-input/uno-periodicity-selector/uno-periodicity-selector.component';
import {UnoButtonComponent} from '../uno/uno-button/uno-button.component';
import {UnoTextComponent} from '../uno/uno-text/uno-text.component';
import {UnoTitleComponent} from '../uno/uno-title/uno-title.component';
import {UnoUuidComponent} from '../uno-input/uno-uuid/uno-uuid.component';
import {UnoIconComponent} from '../uno/uno-icon/uno-icon.component';
import {UnoPhoneNumberComponent} from '../uno-input/uno-phone-number/uno-phone-number.component';
import {UnoDateFrequency} from '../uno-input/uno-date-frequency/uno-date-frequency.component';
import {UnitConverterPipe} from '../../pipes/unit-converter.pipe';
import {UnoSwitchComponent} from '../uno-input/uno-switch/uno-switch.component';
import {UnoAssetSelectorListComponent} from '../uno-input/uno-asset-selector-list/uno-asset-selector-list.component';
import {UnoFormComponent} from './uno-form/uno-form.component';
import {UnoFormFieldSelectorComponent} from './uno-form-field-selector/uno-form-field-selector.component';
import {UnoFormCollapsableFieldComponent} from './uno-form-collapsable-field/uno-form-collapsable-field.component';
import {UnoFormComposedFieldComponent} from './uno-form-composed-field/uno-form-composed-field.component';
import {UnoFormRepetitiveFieldComponent} from './uno-form-repetitive-field/uno-form-repetitive-field.component';
import {UnoFormAttributePipe} from './uno-form/pipes/uno-form-attribute.pipe';
import {UnoFormLabelPipe} from './uno-form/pipes/uno-form-label.pipe';
import {UnoFormCheckBooleanPipe} from './uno-form/pipes/uno-form-check-boolean.pipe';
import {UnoFormEmailValidationPipe} from './uno-form/pipes/uno-form-email-validation.pipe';
import {UnoFormFieldEmptyPipe} from './uno-form/pipes/uno-form-field-empty';
@NgModule({
	providers: [],
	declarations: [UnoFormComponent, UnoFormFieldSelectorComponent, UnoFormComposedFieldComponent, UnoFormRepetitiveFieldComponent, UnoFormCollapsableFieldComponent],
	exports: [UnoFormComponent, UnoFormFieldSelectorComponent, UnoFormComposedFieldComponent, UnoFormRepetitiveFieldComponent, UnoFormCollapsableFieldComponent],
	bootstrap: [],
	imports: [
		IonicModule,
		FormsModule,
		UnoUuidComponent,
		UnoDateTimeComponent,
		UnoImageSelectorComponent,
		UnoButtonComponent,
		UnoTextComponent,
		UnoVideoSelectorComponent,
		UnoAudioSelectorComponent,
		UnoOptionsComponent,
		TranslateModule,
		UnoOptionsMultipleComponent,
		UnoOptionsSearchComponent,
		UnoPeriodicitySelector,
		UnoLateralSelectorSwitchComponent,
		UnoOptionsList,
		UnoAssetSelectorComponent,
		UnoAssetSelectorListComponent,
		UnoTeamSelectorComponent,
		UnoCompanySelectorComponent,
		UnoUserSelectorComponent,
		UnoOptionsLazyComponent,
		UnoGeoPositionComponent,
		UnoAtexInspectionFieldComponent,
		UnoAtexTagGroupComponent,
		UnoFileSelectorComponent,
		UnoQrCodeComponent,
		UnoNfcTagComponent,
		UnoKeyValueComponent,
		UnoTitleComponent,
		UnoSwitchComponent,
		UnoPhoneNumberComponent,
		UnoDateFrequency,
		UnoIconComponent,
		NgClass,
		UnoFormLabelPipe,
		UnoFormAttributePipe,
		UnoFormCheckBooleanPipe,
		UnoFormEmailValidationPipe,
		UnoFormFieldEmptyPipe,
		UnitConverterPipe
	]
})
export class UnoFormModule { }
